
export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`)
  if (element) {
    return element.getAttribute("content")
  }
}

export function findElement(root, selector) {
  if (typeof root === "string") {
    selector = root
    root = document
  }
  return root.querySelector(selector)
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value
  } else if (Array.from) {
    return Array.from(value)
  } else {
    return [].slice.call(value)
  }
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el)
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling)
}

// uzycie
// const blob = fetch('http://example.com/blob.png').then(r => r.blob())
// saveFile(blob, 'blob.png')
export function saveFile(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    const a = document.createElement('a')
    document.body.appendChild(a)
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    a.click()
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }, 0)
  }
}

export function dispatchCustomEvent(dispatcher, name, detail) {
  const event = new CustomEvent(name, { detail: detail })
  dispatcher.dispatchEvent(event)
}

export function humanFilesize(size) {
  let selectedSize = 0
  let selectedUnit = "b"

  if (size > 0) {
    const units = ["TB", "GB", "MB", "KB", "b"]

    for (let i = 0; i < units.length; i++) {
      const unit = units[i]
      const cutoff = Math.pow(1000, 4 - i) / 10

      if (size >= cutoff) {
        selectedSize = size / Math.pow(1000, 4 - i)
        selectedUnit = unit
        break
      }
    }

    selectedSize = Math.round(10 * selectedSize) / 10 // Cutting of digits
  }

  return `${selectedSize} ${selectedUnit}`
}

export function getContrastColorForTagText(color) {
  if (color.length === 4) {
    color = color + color.split('#')[1]
  }
  // Convert color to RGB values
  const hex = color.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  // Calculate color brightness using the WCAG formula
  const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000

  // Return white or black based on brightness threshold
  return (brightness >= 128) ? 'black' : 'white'
}

export function hexToRGBString(color) {
  if (color.length === 4) {
    color = color + color.split('#')[1]
  }
  // Convert color to RGB values
  const hex = color.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return [r, g, b]
}

export function getBackgroundColorInHex(target) {
  const cssObj = window.getComputedStyle(target, null)
  const bgColor = cssObj.getPropertyValue("background-color")
  const color = rgbStringToHex(bgColor)
  return color
}

export function jsonGreedyRequest(url, successHandler, rollbackHandler) {
  successHandler()
  fetch(url).then(r => {
    if (!r.ok) {
      rollbackHandler()
    }
  })
}

function rgbStringToHex(rgbString) {
  const regex = /\b\d{1,3}\b/g
  const rgbArray = rgbString.match(regex)
  const [r, g, b] = rgbArray
  const hexString = rgbToHex(r, g, b)
  return hexString
}

function rgbToHex(r, g, b) {
  return `#${valueToHex(r)}${valueToHex(g)}${valueToHex(b)}`
}

function valueToHex(c) {
  c = parseInt(c)
  return c.toString(16).padStart(2, '0') // padStart zapewni że w razie liczb typu 08 zawsze dostaniemy też 0 z przodu
}
