import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item1", "item2"]
  even = true

  connect() {
    console.log('toggle controller connect')
  }

  toggle() {
    if (this.even) {
      this.item1Target.classList.remove('hidden')
      this.item2Target.classList.add('hidden')
      console.log('item 1 show')
    } else {
      this.item1Target.classList.add('hidden')
      this.item2Target.classList.remove('hidden')
      console.log('item 1 hide')
    }
    this.even = !this.even
  }
}
