import { Application } from "@hotwired/stimulus"

import DropdownController from "controllers/dropdown_controller"
import ToggleController from "controllers/toggle_controller"
const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug = false

window.Stimulus = application

application.register("dropdown", DropdownController)
application.register("toggle", ToggleController)

export { application }
